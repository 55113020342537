<template>
  <b-card body-class="p-0" header-tag="header" class="shadow-sm">
    <template #header>
      <div class="text-center noselect">
        {{ portal.displayname }}
        <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      </div>
    </template>
    <b-card-body v-if="expand" class="pt-3 pb-2">
      <b-row>
        <b-col cols="4">
          <b-row class="mb-2">
            <b-col>
              <b-badge v-if="portal.count" :variant="variantSuccess">{{ portal.count }}</b-badge>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-icon-pencil-square :id="'tt-editPortal-' + portal.id" font-scale="1.2" class="clickable"
                                    @click="editPortal(portal)"/>
              <b-tooltip :target="'tt-editPortal-' + portal.id" triggers="hover">Bearbeiten</b-tooltip>
            </b-col>
          </b-row>
          <b-row v-for="(value, key) in portalData" :key="key">
            <b-col class="text-left">
              {{ key }}
            </b-col>
            <b-col class="text-right">
              <span v-if="key === 'Ablaufdatum'">
                <span v-if="value">{{ value | date }}</span>
                <span v-else>-</span>
              </span>
              <span v-else-if="key === 'Portaladresse'">
                <a v-if="value" :href="value" target="_blank">{{ value | url }}</a>
                <span v-else>-</span>
              </span>
              <span v-else>
                <span v-if="value">{{ value }}</span>
                <span v-else>-</span>
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8" class="ml-auto pl-3">
          <b-row>
            <b-col cols="4">
              <DownloadTile v-if="portalHasDownload" :portalName="portal.displayname"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import DownloadTile from "@/components/fg-software/webPortals/optionalTiles/DownloadTile.vue";

export default {
  name: "PortalRow",
  props: ['portal'],
  components: {
    DownloadTile
  },
  data() {
    return {
      expand: true
    }
  },
  methods: {
    editPortal(data) {
      this.$emit('edit-portal', data)
    },
    refresh() {
      this.$emit('refresh')
    },
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    portalData() {
      if(this.portal) {
        return {
          'Status': !this.portal['inactive'] ? 'Aktiv' : 'Nicht aktiv',
          'Portaladresse': this.portal['portal_address'],
          'Notiz': this.portal['note']
        }
      }
      return null
    },
    portalHasDownload() {
      const needsDownload = ['Adobe', 'ArcGis', 'Autodesk', 'BIMPLUS', 'Cinema 4D', 'Eplan', 'Solibri', 'SwatIO', 'ChatGPT']
      return needsDownload.includes(this.portal.displayname);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
